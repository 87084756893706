._1oYOS{
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    padding: 4px 8px;
}

._2aNF9{
    margin: 0 10px;
}

._1yGdK{
    padding: 0 6px;
}

._249_g{
    flex-basis: 39px !important;
}

._d8Qqp span:first-child{
    /* remove padding left from first span of this class */

    padding-left: 0 !important;
}
._d8Qqp span:last-child{
    /* remove padding left from first span of this class */

    padding-left: 12px !important;
}
