.ant-form-item-explain {
  font-size: 11px;
}
.ant-form-item-label {
  font-size: 12px !important;
  font-weight: 500;
}

.ant-form-item-label label {
  color: #555 !important;
  font-size: 12px !important;
}

.form-full-width {
  display: block;
}

.form-full-width .ant-form-item-control {
  max-width: 100% !important;
}

.form-only-error .ant-form-item-label {
  display: none;
}

.form-only-error .ant-form-item-control .ant-form-item-control-input {
  display: none;
}

.ant-form-item {
  margin-bottom: 1px !important;
}

.ant-modal-title {
  font-size: 14px;
  font-weight: 700;
}
.ant-modal-body {
  padding: 10px 24px 20px 24px;
}

.ant-drawer-body {
  /* padding: 0 !important; */
  /* overflow: hidden !important; */
}

.ant-modal-footer {
  border-top-width: 0;
}

.ant-table {
  border-radius: 6px;
  border: 0.5px solid rgba(#000, 0.12);
  overflow: hidden;
}
.ant-table-content thead tr th {
  font-weight: 700;
  text-transform: capitalize;
  color: #555;
}

.ant-picker-time-panel-column {
  overflow-y: auto;
}

.ant-drawer-body,
.ant-drawer-header {
  background: #edeff6;
}

.custom-antrate svg {
  font-size: 13px;
}

/* tabs-color-hard-change */
/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--blue) !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: var(--blue) !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--blue) !important;
}

.ant-tabs-ink-bar-animated {
  background: var(--blue) !important;
} */

.ant-alert {
  margin-bottom: 10px;
  text-align: center;
}
.ant-alert-message {
  font-size: 12px;
  font-weight: 500;
  /* color: var(--greyDark) !important; */
}
.ant-alert-description {
  font-size: 12px;
}

body {
  font-family: Poppins;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  /* color: white; */
  margin: 0;
}
